<template>
    <div class="list-parlamentares">
        <SpinnerDefault class="text-light" :loading="this.loadingStore.data.telao.loadingVereadores" />
        <div v-if="!this.loadingStore.data.telao.loadingVereadores">
            <div class="parlamentares" v-if="hasTwoTables && !isMobile() && !isPainelEletronico">
                <div class="parlamentares__bg">
                    <ParlamentarHeader />
                    <ParlamentarItemList
                        :parlamentar="vereador"
                        v-for="(vereador, index) in parlamentares"
                        :key="vereador.id"
                        :indice="index"
                        :has-two-tables="hasTwoTables"
                        :is-second-table="false"
                        :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador"
                    />
                </div>

                <div class="parlamentares__bg">
                    <ParlamentarHeader />
                    <ParlamentarItemList
                        :parlamentar="vereador"
                        v-for="(vereador, index) in parlamentares"
                        :key="vereador.id"
                        :indice="index"
                        :has-two-tables="hasTwoTables"
                        :is-second-table="true"
                        :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador"
                    />
                </div>
            </div>

            <div v-else>
                <div class="parlamentares__bg">
                    <ParlamentarHeader />
                    <ParlamentarItemList
                        :parlamentar="vereador"
                        v-for="(vereador, index) in parlamentares"
                        :key="vereador.id"
                        :indice="index"
                        :has-two-tables="false"
                        :is-second-table="false"
                        :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ParlamentarService from '../../domain/ParlamentarService.js';
import ParlamentarItemList from './ParlamentarItemList.vue';
import SpinnerDefault from '../shared/SpinnerDefault.vue';
import LoadingStore from '../../domain/storage/LoadingStore';
import PresenceStore from '../../domain/storage/PresenceStore';
import SessaoServiceTelao from '../../domain/SessaoServiceTelao';
const ParlamentarHeader = () => import('../../components/telao/ParlamentarHeader.vue');

export default {
    components: {
        ParlamentarItemList,
        SpinnerDefault,
        ParlamentarHeader
    },

    props: {
        isPainelEletronico: {
            required: true
        },
        exibirApenasApelidoVereador: {
            type: Boolean,
            required: true,
        }
    },

    data() {
        return {
            sessaoId: null,
            sessaoServiceTelao: new SessaoServiceTelao(this.$route.params.dominio),
            loadingStore: LoadingStore,
            dominio: this.$route.params.dominio,
            parlamentarService: new ParlamentarService(this.$route.params.dominio),
            parlamentares: [],
            presenceStore: PresenceStore,
            hasTwoTables: false
        };
    },

    methods: {
        isMobile() {
            if (screen.width <= 768) {
                return true;
            } else {
                return false;
            }
        }
    },

    mounted: async function() {
        this.presenceStore.methods.clearPresentesAusentes();

        await this.sessaoServiceTelao
            .next()
            .then(response => (this.sessaoId = response.data.sessaoId))
            .catch(err => err);

        const ACOMPANHAMENTO_CHANNEL = window.Echo.channel(
            `portyx_${this.dominio}_sessao${this.sessaoId}_acompanhamento`
        );
        const NAMESPACE_PREFIX = '.App\\Events\\AcompanhamentoSessao\\';

        ACOMPANHAMENTO_CHANNEL.listen(NAMESPACE_PREFIX + 'VereadorMarcouPresenca', response => {
            let vereadorId = response.vereadorId;
            this.$root.$emit('Vereador::attended', { vereadorId });
        });

        this.parlamentarService
            .getCurrentLegislaturaVereadores(this.sessaoId)
            .then(response => {
                this.parlamentares = response.data.vereadores;

                this.parlamentares.sort((a, b) =>
                    a.sessoes.length < b.sessoes.length ? 1 : b.sessoes.length < a.sessoes.length ? -1 : 0
                );

                let quantidadeParlamentares = this.parlamentares.length;

                if (quantidadeParlamentares > 7) {
                    this.hasTwoTables = true;
                }

                this.loadingStore.methods.setTelaoLoadingVereadores(false);
            })
            .catch(err => err.response);
    }
};
</script>

<style scoped lang="scss">
.list-parlamentares {
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}

.parlamentares {
    display: grid;
    gap: 40px;

    @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr;
    }

    &__bg {
        padding: 15px 0;
    }
}
</style>
