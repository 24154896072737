import http from '../http/http-telao';

export default class ParlamentarService {
    constructor(dominio) {
        this._dominio = dominio;
    }

    /**
     * Retorna os vereadores da legislatura atual
     *
     * @param {int} sessaoId
     */
    getCurrentLegislaturaVereadores(sessaoId) {
        return http.post('/legislatura/current/vereadores', {
            dominio: this._dominio,
            sessaoId
        });
    }
}
