<template>
    <transition name="bounce">
        <template v-if="hasTwoTables && isSecondTable && isShowed">
            <template v-if="indice % 2 == 0">
                <ParlamentarVotosItemList 
                    :parlamentarColor="itemClass" 
                    :parlamentar="parlamentar"
                    :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador" 
                />
            </template>
        </template>

        <template v-else-if="hasTwoTables && !isSecondTable && isShowed">
            <template v-if="indice % 2 == 1">
                <ParlamentarVotosItemList 
                    :parlamentarColor="itemClass" 
                    :parlamentar="parlamentar"
                    :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador" 
                />
            </template>
        </template>

        <template v-else-if="isShowed">
            <ParlamentarVotosItemList 
                :parlamentarColor="itemClass" 
                :parlamentar="parlamentar"
                :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador" 
            />
        </template>
    </transition>
</template>

<script>
import PresenceStore from '../../domain/storage/PresenceStore';
import ParlamentarVotosItemList from './ParlamentarVotosItemList';

export default {
    components: {
        ParlamentarVotosItemList
    },

    props: {
        parlamentar: {
            type: Object,
            required: true
        },
        hasTwoTables: {
            type: Boolean,
            required: true
        },
        indice: {
            type: Number,
            required: true
        },
        isSecondTable: {
            type: Boolean,
            required: true
        },
        exibirApenasApelidoVereador: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            itemClass: '',
            isShowed: true,
            presenceStore: PresenceStore
        };
    },

    created: function() {
        this.itemClass = 'nao-presente';

        this.$root.$on('Vereador::attended', payload => {
            if (this.parlamentar.id === payload.vereadorId) {
                this.makePresence();
                this.subAbsent();
            }
        });

        if (this.parlamentar.sessoes.length > 0) {
            this.makePresence();
            return;
        }
        this.makeAbsent();
    },

    methods: {
        subAbsent() {
            if (!this.isSecondTable && !this.hasTwoTables) this.presenceStore.methods.subAbsent();
        },

        makeAbsent() {
            if (!this.isSecondTable && !this.hasTwoTables) this.presenceStore.methods.addAbsent();
        },

        makePresence() {
            if (!this.isSecondTable && !this.hasTwoTables) this.presenceStore.methods.addPresent();
            this.presentAnimation();
        },

        presentAnimation() {
            this.isShowed = false;
            setInterval(() => {
                this.itemClass = 'presente';
                this.isShowed = true;
            }, 100);
        }
    }
};
</script>

<style scoped>
.presente {
    font-weight: bold;
    color: #36dc69;
}

.nao-presente {
    color: rgba(255, 255, 255, 0.7);
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
</style>
