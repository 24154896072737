<template>
    <div class="spinner-border text-primary" v-show="loading" role="status">
        <span class="sr-only">Carregando...</span>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    }
};
</script>
