<template>
    <transition name="bounce">
        <div :class="color" class="parlamentar">
            <h3 class="parlamentar-item">
                {{ nomeVereador }}
            </h3>
            <h3 class="parlamentar-item">
                {{ parlamentar.partido.sigla }}
            </h3>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        parlamentar: {
            type: Object,
            required: true
        },
        parlamentarColor: {
            type: String,
            required: false
        },
        exibirApenasApelidoVereador: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        color() {
            if (this.parlamentar.pivot.votacao_opcao_id === 1) return 'voto-sim';
            if (this.parlamentar.pivot.votacao_opcao_id === 2) return 'voto-ausente';
            if (this.parlamentar.pivot.votacao_opcao_id === 3) return 'voto-abstencao';
            if (this.parlamentar.pivot.votacao_opcao_id === 4) return 'voto-nao-vota';
            if (this.parlamentar.pivot.votacao_opcao_id === 5) return 'voto-nao';
            if (this.parlamentarColor) return this.parlamentarColor;
            return '';
        },
        nomeVereador() {
            const apelido = this.parlamentar.apelido || '';
            return (this.exibirApenasApelidoVereador && apelido) ? apelido : this.parlamentar.pessoa.nome;
        }
    }
};
</script>

<style scoped lang="scss">
.parlamentar {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &-item {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        text-align: left;

        @media (min-width: 1000px) {
            font-size: 1.4em;
        }

        @media (min-width: 2000px) {
            font-size: 2.75em;
        }
    }

    &:last-child {
        border: none;
    }
}

.voto {
    &-sim {
        color: #50c5b7;
    }

    &-ausente {
        color: #b7b9cc;
    }

    &-abstencao {
        color: #f9f591;
    }

    &-nao-vota {
        color: #91b5f9;
    }

    &-nao {
        color: #ff7d6a;
    }
}
</style>
